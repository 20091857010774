import {hasDirtyFields} from '@github-ui/has-interactions'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

// Enable data-confirm behavior on cancel button when there are dirty changes
// on the form.
//
// PDI: It'd be nice if data-confirm exposed a custom event that we could cancel
// if the form had no changes preventing the confirm dialog.
function enableBehaviour(event: Event) {
  const form = event.currentTarget
  if (!(form instanceof HTMLElement)) return
  const button = form.querySelector<HTMLElement>('.js-milestone-edit-cancel')!
  const message = button.getAttribute('data-confirm-changes')
  if (!message) return

  if (hasDirtyFields(form)) {
    button.setAttribute('data-confirm', message)
  } else {
    button.removeAttribute('data-confirm')
  }
}

on('change', '.js-milestone-edit-form', enableBehaviour)
on('click', '.js-milestone-edit-form', enableBehaviour)
