// Bulk editor for Issues.
//
// This handles selection of multiple issues and then *doing things* to them:
// things like labeling, user assignment, and so on.

import {fetchPoll} from '@github-ui/fetch-utils'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {softNavigate} from '@github-ui/soft-navigate'

const loadables = new WeakMap()
const pendingForms = new WeakMap()

on('change', '.js-issues-list-check', function () {
  // Enter triage mode once one item is checked.
  const checked = !!document.querySelector('.js-issues-list-check:checked')
  document.querySelector<HTMLElement>('#js-issues-toolbar')!.classList.toggle('triage-mode', checked)

  // Mark menus as reloadable.
  for (const menu of document.querySelectorAll('.js-issue-triage-menu')) {
    loadables.set(menu, true)
  }
})

on('toggle', '.js-issue-triage-menu', toggleMenu, {capture: true})
on('details-menu-selected', '.js-issue-triage-menu details-menu', selectItem, {capture: true})

function toggleMenu(event: Event) {
  const details = event.currentTarget as Element
  if (details.hasAttribute('open')) {
    loadMenu(details)
  } else {
    submitMenu(details)
  }
}

// Fetch menu items based on currently selected issues.
function loadMenu(details: Element) {
  if (!loadables.has(details)) return

  const template = document.querySelector<HTMLTemplateElement>('.js-triage-loader-template')!
  const container = details.querySelector<HTMLElement>('.js-triage-deferred-content')!
  container.textContent = ''
  container.append(template.content.cloneNode(true))

  const url = menuUrl(details.getAttribute('data-url')!, checkedIssues())
  container.querySelector<HTMLElement>('include-fragment')!.setAttribute('src', url)
  loadables.delete(details)
}

// Show progress spinner until page reloads.
function toggleLoading(menu: Element, show: boolean) {
  const toolbar = menu.closest<HTMLElement>('.js-issues-toolbar-triage')!
  toolbar.querySelector<HTMLElement>('.js-issue-triage-spinner')!.hidden = !show
  toolbar.querySelector<HTMLElement>('.js-issue-triage-error')!.hidden = true
}

function toggleError(menu: Element, show: boolean) {
  const toolbar = menu.closest<HTMLElement>('.js-issues-toolbar-triage')!
  toolbar.querySelector<HTMLElement>('.js-issue-triage-spinner')!.hidden = true
  toolbar.querySelector<HTMLElement>('.js-issue-triage-error')!.hidden = !show
}

// Save menu selections and wait for job to complete before refreshing.
async function submitMenu(details: Element) {
  const form = details.querySelector<HTMLFormElement>('form')!
  if (!pendingForms.has(form)) return

  toggleLoading(details, true)

  try {
    const response = await fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
    })

    if (!response.ok) {
      const responseError = new Error()
      const statusText = response.statusText ? ` ${response.statusText}` : ''
      responseError.message = `HTTP ${response.status}${statusText}`
      throw responseError
    }
    const data = await response.json()

    pendingForms.delete(form)
    await fetchPoll(data.job.url, {headers: {accept: 'application/json'}})

    softNavigate(window.location.href, {action: 'replace'})
  } catch {
    toggleError(details, true)
  }
}

// Copy selected menu item into hidden field and schedule submit on menu close.
function selectItem(event: CustomEvent) {
  const item = event.detail.relatedTarget as Element
  const form = item.closest<HTMLFormElement>('form')!
  const input = createInput(item)
  const fields = form.querySelector<HTMLElement>('.js-issues-triage-fields')!
  const existing = fields.querySelector(`[name='${input.name}']`)
  if (existing) {
    existing.replaceWith(input)
  } else {
    fields.append(input)
  }
  pendingForms.set(form, true)
}

// Create a hidden form input from a selected menu item.
function createInput(item: Element): HTMLInputElement {
  const selected = item.getAttribute('aria-checked') === 'true'
  const name = item.getAttribute('name') || item.getAttribute('data-name')
  const value = item.getAttribute('value') || item.getAttribute('data-value')

  const input = document.createElement('input')
  input.type = 'hidden'
  input.name = name!

  switch (item.getAttribute('role')) {
    case 'menuitem':
    case 'menuitemradio':
      input.value = value!
      break
    case 'menuitemcheckbox':
      input.value = selected ? value! : '0'
      break
  }

  return input
}

// Returns the list of selected issues to bulk update.
function checkedIssues(): Array<[string, string]> {
  return Array.from(document.querySelectorAll<HTMLInputElement>('.js-issues-list-check:checked')).map(el => [
    el.name,
    el.value,
  ])
}

// Build the menu's remote URL from currently selected issues.
function menuUrl(baseUrl: string, pairs: Array<[string, string]>): string {
  const url = new URL(baseUrl, window.location.origin)
  const params = new URLSearchParams(url.search)
  for (const [name, value] of pairs) {
    params.append(name, value)
  }
  url.search = params.toString()
  return url.toString()
}
