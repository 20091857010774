// Add .selected class to row parent of selected checkboxes.
//
// Future CSS .issue-row:has(input[type=checkbox]:checked)
//
// TODO: Selector should be '.js-issue-row .js-issues-list-check:checked' once
// observe supports descendant selectors.

import {changeValue} from '@github-ui/form-utils'
import {isShortcutAllowed} from '@github-ui/hotkey/keyboard-shortcuts-helper'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onFocus} from '@github-ui/onfocus'

observe('.js-issue-row .js-issues-list-check:checked', {
  add(el) {
    el.closest<HTMLElement>('.js-issue-row')!.classList.add('selected')
  },
  remove(el) {
    el.closest<HTMLElement>('.js-issue-row')!.classList.remove('selected')
  },
})

// Hitting 'x' on the current issue with toggle the checkbox
on('navigation:keydown', '.js-issue-row', function (event) {
  if (!isShortcutAllowed(event.detail.originalEvent)) return

  if (event.detail.hotkey === 'x') {
    toggleRowCheckbox(event.currentTarget)
    event.preventDefault()
    event.stopPropagation()
  }
})

// Using the `/` hotkey will highlight the whole input by default. We'd rather
// place the cursor at the far right of the box instead, so you can immediately
// add to the existing query.
onFocus('#js-issues-search', function (el) {
  const input = el as HTMLInputElement
  // eslint-disable-next-line no-self-assign
  input.value = input.value
})

function toggleRowCheckbox(row: Element) {
  const checkbox = row.querySelector('.js-issues-list-check')
  if (checkbox instanceof HTMLInputElement) {
    changeValue(checkbox, !checkbox.checked)
  }
}
