import {remoteForm} from '@github/remote-form'

remoteForm('.js-undo-issue-event-form', async (form, wants) => {
  await wants.text()
  const url = form.getAttribute('action')!
  form.remove()
  const otherUndoForms = document.querySelectorAll(`.js-undo-issue-event-form[action="${url}"]`)
  for (let i = 0; i < otherUndoForms.length; i++) {
    otherUndoForms[i]!.remove()
  }
})
