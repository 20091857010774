import {debounce} from '@github/mini-throttle'
import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
import {fromEvent} from '@github-ui/subscription'
import {hasInteractions} from '@github-ui/has-interactions'
import {isShortcutAllowed} from '@github-ui/hotkey/keyboard-shortcuts-helper'
import {refocus as navigationFocus} from '@github-ui/input-navigation-behavior'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import type {SortableEvent as SortableJsEvent} from '@github/sortablejs'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {preserveAnchorNodePosition} from 'scroll-anchoring'
import {remoteForm} from '@github/remote-form'
import {requestSubmit} from '@github-ui/form-utils'
import verifySsoSession from '../sso'

type SortableEvent = SortableJsEvent & {
  trackingLabel?: string
}

const sortables = new WeakMap()
const sorting = new WeakMap()

async function refreshIssueList(elem: Element) {
  if (hasInteractions(elem)) {
    return
  }

  const url = elem.getAttribute('data-url')!
  const html = await fetchSafeDocumentFragment(document, url)
  preserveAnchorNodePosition(document, () => {
    elem.replaceWith(html)
  })
}

observe('.js-milestone-issues', {
  subscribe: el =>
    fromEvent(el, 'socket:message', async function (event: Event) {
      const list = event.currentTarget as Element
      const data = (event as CustomEvent).detail.data
      const container = list.querySelector<HTMLElement>('.js-milestone-issues-container')!
      if (sorting.has(container)) {
        sorting.delete(container)
        return
      }
      await verifySsoSession()
      const clientUID = document.querySelector('.js-client-uid')
      // Don't process the live update for the user doing the re-prioritizing
      if (!(clientUID instanceof HTMLInputElement && clientUID.value === data.client_uid)) {
        refreshIssueList(list)
      }
    }),
})

remoteForm('.js-milestone-sort-form', async function (form, wants) {
  const response = await wants.json()
  const json = response.json

  const feedback = form.querySelector<HTMLElement>('.js-milestone-reorder-feedback')!
  feedback.textContent = ''

  if (json.error) {
    /* eslint-disable-next-line github/no-d-none */
    form.querySelector<HTMLElement>('.js-milestone-changed')!.classList.remove('d-none')
  } else {
    form.querySelector<HTMLInputElement>('.js-timestamp')!.value = json.updated_at
    feedback.textContent = feedback.getAttribute('data-success-text') || ''
  }
})

function milestoneIssueAt(container: Element, index: number): Element {
  return container.querySelectorAll('.js-draggable-issue')[index]!
}

const handleUpdate = debounce(function (event: SortableEvent) {
  const {newIndex, item} = event
  const container = item.closest<HTMLElement>('.js-milestone-issues-container')!
  const itemID = item.getAttribute('data-id') || ''
  const previousIssue = milestoneIssueAt(container, newIndex! - 1)
  const previousIssueID = previousIssue && previousIssue.getAttribute('data-id')
  const form = container.closest<HTMLFormElement>('.js-milestone-sort-form')!

  form.querySelector<HTMLInputElement>('.js-item-id')!.value = itemID
  form.querySelector<HTMLInputElement>('.js-prev-id')!.value = previousIssueID || ''

  sorting.set(container, true)
  requestSubmit(form)
}, 200)

// Usually, handleUpdate is called internally by the Sortable library when a user makes an update
// by dragging/dropping. In the event of a keyboard shortcut update, we need to call handleUpdate
// ourselves.
function updateManually(container: HTMLElement, elem: HTMLElement) {
  handleUpdate({
    oldIndex: undefined,
    item: elem,
    newIndex: Array.from(container.querySelectorAll('.js-draggable-issue')).indexOf(elem),
    trackingLabel: 'keyboard-shortcut',
  })

  navigationFocus(elem.closest<HTMLElement>('.js-navigation-container')!, elem)
}

on('click', '.js-draggable-issue .js-sortable-button', async function ({currentTarget: button}) {
  const {moveWithButton} = await import('../sortable-behavior')
  moveWithButton(button as HTMLElement, button.closest<HTMLElement>('.js-draggable-issue')!, handleUpdate)
})

// Support keyboard shortcuts
on('navigation:keydown', '.js-draggable-issues-container .js-draggable-issue', function (event) {
  if (!isShortcutAllowed(event.detail.originalEvent)) return
  const target = event.currentTarget as HTMLElement

  const container = target.closest<HTMLElement>('.js-draggable-issues-container')!

  if (event.detail.hotkey === 'J') {
    const nextElement = target.nextElementSibling
    if (nextElement) {
      nextElement.after(target)
      updateManually(container, target)
      event.preventDefault()
      event.stopPropagation()
    }
  } else if (event.detail.hotkey === 'K') {
    const prevElement = target.previousElementSibling
    if (prevElement) {
      prevElement.before(target)
      updateManually(container, target)
      event.preventDefault()
      event.stopPropagation()
    }
  }
})

async function setupSortable(el: Element) {
  if (sortables.has(el)) {
    return // We already have one.
  }

  const {Sortable} = await import('../sortable-behavior')
  const sortable = Sortable.create(el, {
    animation: 150,
    item: '.js-draggable-issue',
    handle: '.js-drag-handle',
    onUpdate: handleUpdate,
    chosenClass: 'is-dragging',
  })

  sortables.set(el, sortable)
}

function removeSortable(el: Element) {
  const sortable = sortables.get(el)

  if (sortable) {
    sortable.destroy()
  }
}

observe('.js-draggable-issues-container', {
  add: setupSortable,
  remove: removeSortable,
})
