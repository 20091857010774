import {controller, target} from '@github/catalyst'

@controller
class TrackedIssuesProgressElement extends HTMLElement {
  @target label: HTMLElement
  @target progress: HTMLElement
  @target checklist: HTMLElement
  @target stroke: SVGCircleElement

  timer: number

  static observedAttributes = ['data-total', 'data-completed']

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (oldValue === null || oldValue === newValue) return
    if (this.timer) {
      cancelAnimationFrame(this.timer)
    }
    this.timer = requestAnimationFrame(() => this.update())
  }

  disconnectedCallback() {
    if (this.timer) {
      cancelAnimationFrame(this.timer)
    }
  }

  update() {
    const completed = Number(this.getAttribute('data-completed') || 0)
    const total = Number(this.getAttribute('data-total') || 0)
    this.label.textContent = this.getLabelContent(completed, total)
    if (this.progress && total > 0) {
      const {parentElement} = this.progress
      const circumference = Number(this.progress.getAttribute('data-circumference') || 0)
      const strokeWidth = Number(this.stroke.getAttribute('stroke-width') || 0)
      const percentage = (completed / total) * 100
      const offset = percentage < 100 ? ((100 - percentage) / 100) * circumference + strokeWidth / 2 : 0
      // Animate the progress circle
      this.stroke.setAttribute('stroke-dashoffset', offset.toString())
      if (completed === 0) {
        // The icon is wrapped in a span due to a bug in safari that breaks rendering when rotation is applied
        // Thus we need to toggle visibility of the parent span
        if (parentElement) parentElement.style.display = 'none'
        this.checklist.style.display = 'flex'
      } else {
        if (parentElement) parentElement.style.display = 'flex'
        this.checklist.style.display = 'none'
      }
    }
  }

  getLabelContent(completed: number, total: number) {
    if (completed > 0) {
      if (completed === total) {
        return `${this.pluralizeTasks(total)} done`
      }
      return `${completed} of ${this.pluralizeTasks(total)}`
    }
    return this.pluralizeTasks(total)
  }

  pluralizeTasks(total: number) {
    return total === 1 ? '1 task' : `${total} tasks`
  }
}
