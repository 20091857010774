// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {insertText} from '@github-ui/text'
import {hasCustomTitle, validateContent} from '@github-ui/tasklist-validation'

on('click', '.js-add-tasklist-body-button', async function (event) {
  if (!(event.target instanceof Element)) return
  let container = event.target.closest<HTMLElement>('.js-previewable-comment-form')!
  const button = event.target.closest<HTMLButtonElement>('button')
  const spinner = button?.parentElement?.querySelector<HTMLButtonElement>('.add-tasklist-spinner')
  let enterUIMode = false

  if (!container) {
    // if we didn't find the container with `closest`, try the `for` attribute
    const buttonAttributes = button?.attributes
    if (!buttonAttributes) return
    enterUIMode = buttonAttributes.getNamedItem('class')?.value.includes('js-tasklist-submit') || false

    const forAttribute = buttonAttributes.getNamedItem('for')?.value
    const formElement = forAttribute ? (document.querySelector(`#${forAttribute}`) as HTMLFormElement) : null
    if (!formElement) return

    if (enterUIMode) {
      // This is handling the UI scenario, when the button is clicked
      // from the view issue
      button.toggleAttribute('hidden', true)
      spinner?.toggleAttribute('hidden', false)

      // Prevent live updates
      spinner?.classList.add('is-dirty')

      try {
        const comment = button.closest<HTMLElement>('.js-comment')!

        await new Promise<void>((resolve, reject) => {
          const ignored = comment.dispatchEvent(
            new CustomEvent('tracking-block:add-tasklist', {
              bubbles: true,
              cancelable: true,
              detail: {resolve, reject},
            }),
          )

          // If the event was not ignored, we know that the event was handled.
          if (ignored) resolve()
        })

        button.toggleAttribute('hidden', false)
        spinner?.toggleAttribute('hidden', true)

        // Allow next live update to pass through
        spinner?.classList.remove('is-dirty')
      } catch {
        location.reload()
      }
      return
    } else {
      // This is handling the new / edit issue view
      container = formElement.querySelector<HTMLFormElement>('.js-previewable-comment-form')!
      if (!container) return
    }
  }

  addTasklistToContainer(event, container)
})

/**
 * When the user clicks on the "add tasklist" button, add a tasklist to the textarea in the
 * issue edit body.
 */
on('click', '.js-add-tasklist-button', function (event) {
  if (!(event.target instanceof Element)) return
  const container = event.target.closest<HTMLElement>('.js-previewable-comment-form')!
  addTasklistToContainer(event, container)
})

function addTasklistToContainer(
  event: MouseEvent & {currentTarget: Element},
  container: HTMLElement,
  content?: string,
) {
  const comment = container.querySelector<HTMLTextAreaElement>('textarea.js-comment-field')!
  let startCursor = comment.selectionStart
  let endCursor = comment.selectionEnd
  if (startCursor === 0 && endCursor === 0 && comment.value.length) {
    startCursor = endCursor = (comment.value || '').length
  }
  comment.setSelectionRange(startCursor, endCursor)

  let title = '### Tasks\n'
  content = content === '' ? '' : '- [ ] Add a draft title or issue reference here'
  if (startCursor !== endCursor) {
    // If there is a selection, save it and remove from the comment
    content = comment.value.slice(startCursor, endCursor)
    comment.value = comment.value.replace(content, '')

    // Find custom title if any and validate lines
    title = hasCustomTitle(content) ? '' : title
    content = validateContent(content)

    // Set cursor back to the start of the original selection
    comment.selectionStart = startCursor
    comment.selectionEnd = startCursor
  }
  const spacing = comment.value === '' ? '' : '\n'
  const text = `${spacing}\`\`\`[tasklist]\n${title}${content}\n\`\`\``
  insertText(comment, text, {appendNewline: true})

  // Handling case where newline is prepended
  const cursorMove = text.length + 1
  comment.selectionStart = startCursor + cursorMove
  comment.selectionEnd = startCursor + cursorMove
}
