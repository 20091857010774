// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

observe('.js-autofocus-cursor', {
  add(el: Element) {
    if (el instanceof HTMLInputElement && el.value) {
      el.setSelectionRange(el.value.length, el.value.length)
    }
  },
})
