import type SortableI from '@github/sortablejs'
import type {SortableEvent} from '@github/sortablejs'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

let sortable: SortableI | undefined
let siblingListItem: Element | null = null

function handleStart(event: SortableEvent) {
  const {item, oldIndex} = event
  siblingListItem = item.parentNode!.children[oldIndex! + 1]!
}

async function handleUpdate(event: SortableEvent): Promise<void> {
  const {oldIndex, newIndex, item} = event

  if (oldIndex === newIndex) {
    return
  }

  const form = item.closest<HTMLFormElement>('.js-pinned-issues-reorder-form')!
  sortable!.option('disabled', true)

  const response = await fetch(form.action, {
    method: form.method,
    body: new FormData(form),
    headers: {'X-Requested-With': 'XMLHttpRequest'},
  })
  if (!response.ok) {
    const list = item.parentNode!

    if (siblingListItem) {
      list.insertBefore(item, siblingListItem)
    } else {
      list.appendChild(item)
    }
  } else {
    sortable!.option('disabled', false)
  }
}

observe('.js-pinned-issues-reorder-list', {
  async add(el) {
    const {Sortable} = await import('../sortable-behavior')
    sortable = Sortable.create(el, {
      animation: 150,
      item: '.js-pinned-issue-list-item',
      handle: '.js-pinned-issue-reorder',
      onUpdate: handleUpdate,
      onStart: handleStart,
      chosenClass: 'is-dragging',
    })
  },
})

on('submit', '.js-pinned-issues-reorder-form', function (event) {
  event.preventDefault()
})

on('click', '.js-pinned-issue-list-item .js-sortable-button', async function ({currentTarget}) {
  const button = currentTarget as HTMLElement
  const {moveWithButton} = await import('../sortable-behavior')
  moveWithButton(button, button.closest<HTMLElement>('.js-pinned-issue-list-item')!, handleUpdate)
})
